import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";


const Map = ({ svgClass }) => {
  const { rpdata } = useContext(GlobalDataContext);
  return (

    <div className="pb-20 md:pb-28">
      <div className=" py-5">
        <div className="md:w-[95%] mx-auto mt-[60px]">
          {
            rpdata?.labels?.general?.titleMap ?
              <h2 className="text-center pb-5">
                {rpdata?.labels?.general?.titleMap}
              </h2>
              :
              <h2 className="text-center text-black pb-5">
                We Cover {rpdata?.dbPrincipal?.miles} Miles Around {rpdata?.dbPrincipal?.location?.[0].address}
              </h2>
          }

        </div>

      </div>
      <div className={svgClass ? svgClass : `svgTriangulobotton`}>
        {
          rpdata?.dbPrincipal?.location.length > 1 ?

            <Tabs>
              <TabList>
                <div className="flex flex-col md:flex-row flex-wrap justify-center text-center px-5 -mt-9">
                  {
                    rpdata?.dbPrincipal?.location?.map((item, index) => {
                      return <Tab key={index}>{item.address}</Tab>;
                    })
                  }
                </div>
              </TabList>
              {
                rpdata?.dbPrincipal?.location?.map((item, index) => {
                  return (
                    <TabPanel key={index}>
                      <iframe
                        loading="lazy"
                        title="Cover Locations"
                        src={item.url}
                        style={{ width: "100%", height: "600px" }}
                      />
                    </TabPanel>
                  )
                })
              }
            </Tabs>
            :
            <iframe
              src={rpdata?.dbPrincipal?.location?.[0].url}
              loading="lazy"
              title="Cover Locations"
              className="w-full h-[500px]"
            />

        }
      </div>
    </div>
  )
}

export default Map
